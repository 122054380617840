<template>
  <div class="va-row">
    <div class="flex md1"></div>
    <div class="flex md9">
      <vuestic-widget :headerText="'Esqueci minha senha - Confirmação' | translate">
        <h4>Falta Pouco...</h4>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <input id="simple-input"
v-model="senha.new_password1"
@keyup="validar"
type="password"
required>
              <label class="control-label" for="simple-input">
                Nova Senha
              </label>
              <i class="bar" :class="{is_valid: valido}"></i>
            </div>
          </div> <template v-if="erros"> <span class="alert-danger"
v-for="e in erros.new_password1 || []"
:key="e">{{e}}</span> </template>
        </fieldset>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <input
                id="simple-input1"
                v-model="senha.new_password2"
                @keyup="validar"
                type="password"
                required
              >
              <label class="control-label" for="simple-input1">
                {{'Confirmar Senha'
                | translate}}
              </label>
              <i class="bar" :class="{is_valid: valido, not_valid: nao_valid}"></i>
            </div>
          </div> <template v-if="erros"> <span class="alert-danger"
v-for="e in erros.new_password2 || []"
:key="e">{{e}}</span> </template>
        </fieldset>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <button class="btn btn-info btn-micro" @click="mudarSenha()">Alterar</button>
            </div>
          </div>
        </fieldset>
      </vuestic-widget>
    </div>
  </div>
</template>

<script>
export default {
  name: 'esqueci-minha-senha-confirmacao',

  data() {
    return {
      show: true,
      valido: false,
      nao_valid: false,
      erros: null,
      senha: {
        new_password1: '',
        new_password2: '',
        uid: '',
        token: '',
      },
    };
  },
  created() {
    this.senha.uid = this.$router.history.current.params.uid;
    this.senha.token = this.$router.history.current.params.token;
  },
  methods: {
    showSmallModal() {
      this.$refs.smallModal.open();
    },
    validar() {
      if (this.senha.new_password2 && this.senha.new_password1) {
        this.valido = true;
        this.nao_valid = false;
      }
      if (this.senha.new_password1 === this.senha.new_password2) {
        this.valido = true;
        this.nao_valid = false;
      } else if (this.senha.new_password1 !== this.senha.new_password2) {
        this.valido = false;
        this.nao_valid = true;
      }
      if (this.senha.new_password2 && this.senha.new_password1) {
        this.valido = false;
        this.nao_valid = true;
      } else if (!this.senha.new_password1 && !this.senha.new_password2) {
        this.valido = false;
        this.nao_valid = false;
      }
    },
    mesage(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 5000,
      });
    },
    mudarSenha() {
      this.$axios.post('/rest-auth/password/reset/confirm/', this.senha).then(() => {
        this.mesage('success', 'Senha alterada!');
        this.$router.push({ name: 'login' });
      }).catch((err) => {
        this.erros = err.response.data;
        this.mesage('error', 'Ocorreu um erro');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.is_valid {
  color: green !important;
  border-color: green !important;
  &::before {
    content: "";
    background: green !important;
  }
}
.not_valid {
  border-color: red !important;
  &::before {
    content: "";
    background: red !important;
  }
}
</style>
